// src/utils/dateUtils.js

export function getFormattedDateTime(oneHourAgo = false, daysAgo = 0) {
    // Get the current date and time
    const now = new Date();
    
    // Calculate one hour before the current time if needed
    let dateToFormat = oneHourAgo ? new Date(now.getTime() - 60 * 60 * 1000) : now;
    
    // Subtract the specified number of days
    if (daysAgo > 0) {
      dateToFormat = new Date(dateToFormat.getTime() - daysAgo * 24 * 60 * 60 * 1000);
    }
    
    // Format the date to "YYYY-MM-DDTHH:mm:ss.000"
    const year = dateToFormat.getFullYear();
    const month = String(dateToFormat.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(dateToFormat.getDate()).padStart(2, '0');
    const hours = String(dateToFormat.getHours()).padStart(2, '0');
    const minutes = String(dateToFormat.getMinutes()).padStart(2, '0');
    const seconds = String(dateToFormat.getSeconds()).padStart(2, '0');
    
    // Combine all parts into the desired format
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000`;
    
    return formattedDate;
  }
  
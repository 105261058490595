// src/utils/apiUtils.js

import { getFormattedDateTime } from './dateUtils';

// Function to get the token
export const fetchToken = async () => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      "Identifier": "mm@apply.no",
      "Secret": "Mehdi!234^"
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Tokens/apply/getToken", requestOptions);
      const result = await response.json(); // Parse JSON response
      // console.log("Type of result:", typeof result);
      // console.log("Result:", result);      
      return result.accessToken; // Return only the accessToken
    } catch (error) {
      console.error("Error fetching token: ", error);
      throw error;
    }
  };
  
  // Function to fetch sensor data using the token
  export const fetchSensorData = async (token) => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Cookie", "ARRAffinity=ae1ab7d4e7f47f0bbfba2489eda7264d96e883ca3ac083c946746aff4bbb8950; ARRAffinitySameSite=ae1ab7d4e7f47f0bbfba2489eda7264d96e883ca3ac083c946746aff4bbb8950");

    const sensorIndexes = ["0", "1", "2", "3", "4", "5", "6", "7"];
    const fetchPromises = sensorIndexes.map(async (sensorIndex) => {
      const raw = JSON.stringify({     
        GatewayId: "APPLY1",
        PredictorId: "APPLY_EWON_ONS",
        SensorIndex: sensorIndex,
        FromDate: getFormattedDateTime(false, 4), // 4 days ago
        ToDate: getFormattedDateTime(true), // Use calculated one hour before
      });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Gateways/apply/sensordata", requestOptions);
      const jsonData = await response.json();
      return jsonData; // Return the data for this sensor index
    } catch (error) {
      console.error(`Error fetching data for sensor ${sensorIndex}: `, error);
      return null; // Return null in case of error
    }
  });

  // Wait for all promises to resolve
  const sensorDataArray = await Promise.all(fetchPromises);

  // Filter out any null values (in case any sensor data failed to fetch)
  return sensorDataArray.filter(data => data !== null);
};





  // Function to fetch notifications data using the token
  export const fetchNotificationsData = async (token) => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Cookie", "ARRAffinity=ae1ab7d4e7f47f0bbfba2489eda7264d96e883ca3ac083c946746aff4bbb8950; ARRAffinitySameSite=ae1ab7d4e7f47f0bbfba2489eda7264d96e883ca3ac083c946746aff4bbb8950");
  


    const raw = JSON.stringify({
      "PageSize": 4,
      "PageNumber": 1,
      "FromDate": getFormattedDateTime(false, 10), // 4 days ago 
      "ToDate": getFormattedDateTime(false), // Use calculated one hour before 
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
  
    try {
      const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Gateways/apply/AllNotificationsList", requestOptions);
      const jsonNotificationsData = await response.json();
      return jsonNotificationsData;
    } catch (error) {
      console.error("Error fetching notifications data: ", error);
      throw error;
    }
  };
  

  // Function to fetch sensor data for a specific SensorIndex
export const fetchChartData = async (token, sensorIndex) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    GatewayId: "631390111A67BFD4",
    PredictorId: "D5:F9:2B:B3:34:BE",
    SensorIndex: sensorIndex.toString(), // Set the SensorIndex dynamically
    FromDate: getFormattedDateTime(false, 4), // 4 days ago 
    ToDate: getFormattedDateTime(true), // Use calculated one hour before 
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  try {
    const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Gateways/apply/sensordata", requestOptions);
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching sensor data: ", error);
    throw error;
  }
};

// Main
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { fetchToken, fetchNotificationsData } from './utils/apiUtils';
// UI
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-carousel/dist/react-bootstrap-carousel.css';
import './App.css';
// Components

// import Commands from "./components/Commands";
// import MyNavbar from "./components/MyNavbar";
// import VectaryModel from './components/VectaryModel';
// import TemperatureChart from './components/TemperatureChart';


// added ++
import Home from "./components/Home";
import Mode from "./components/Mode";
import Notifications from "./components/Notifications";
import Logbook from "./components/Logbook";
import SystemInfo from "./components/SystemInfo";
import Login from "./components/Login";


function App() {

  // useStates
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track if the user is logged in
  const [token, setToken] = useState(null);
  const [notifData, setNotifData] = useState([]);
  // const [allLogBook, setLogBook] = useState([]);


    useEffect(() => {
      if (isLoggedIn) { // Fetch data only if the user is logged in
        const getTokenAndData = async () => {
            try {
                // Getting Token
                const token = await fetchToken();
                setToken(token);



                // notifications data
                const jsonNotifData = await fetchNotificationsData(token);
                setNotifData(jsonNotifData.notificationsList); // Set Notifications data

            } catch (error) {
                console.error("Error fetching token or sensor data: ", error);
            }
        };

      
        getTokenAndData();
    }
    }, [isLoggedIn]);



    const handleLogin = () => {
      setIsLoggedIn(true); // Set login state to true
    };

    if (!isLoggedIn) {
      return <Login onLogin={handleLogin} />; // Render the login screen if not logged in
    }


// console.log(allModalData);

return (
    <div className="App">
      <Router>
      <Routes>
      <Route path="/login" element={<Login onLogin={handleLogin} />} />
        {isLoggedIn && <Route path="/" element={<Home token={token} />} />}
        {isLoggedIn && <Route path="/mode" element={<Mode  token={token} />} />}
        {isLoggedIn && <Route path="/notifications" element={<Notifications notificationsList={notifData}/>} />}
        {isLoggedIn && <Route path="/logbook" element={<Logbook token={token}  />} />}
        {isLoggedIn && <Route path="/system-info" element={<SystemInfo />} />}
      </Routes>
      </Router>
    </div>
);
}

export default App;

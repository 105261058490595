import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Table } from 'react-bootstrap';
import MyNavbar from './MyNavbar';

const Logbook = ({token, data}) => {
  const [show, setShow] = useState(false);
  const [entries, setEntries] = useState([]);
  const [formData, setFormData] = useState({ message: '' });


  useEffect(() => {
    fetchLogBookEntries();
  }, []);

  const fetchLogBookEntries = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Cookie", "ARRAffinity=ca5ffd0b6d99becd7bc669f4937089ccc7bd60fd1a2bafaed8abfcec6ffcbde1; ARRAffinitySameSite=ca5ffd0b6d99becd7bc669f4937089ccc7bd60fd1a2bafaed8abfcec6ffcbde1");

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch("https://dev-ideation-api.azurewebsites.net/api/Users/apply/logBook", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(result => {
        // console.log("Fetched logbook entries:", result);
        if (result.logs) {
          setEntries(result.logs); // Assuming the API returns an object with a `logs` array
        } else {
          console.warn("No logs found in the response");
        }
      })
      .catch(error => console.error("Error fetching logbook entries:", error));
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({ message: formData.message });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch("https://dev-ideation-api.azurewebsites.net/api/Users/apply/logBook", requestOptions)
      .then(response => response.json())
      .then(result => {
        // console.log("New entry added:", result);
        fetchLogBookEntries(); // Refresh log entries after adding a new one
      })
      .catch(error => console.error("Error adding logbook entry:", error));

    setFormData({ message: '' });
    handleClose();
  };


  return (
    <>
      <MyNavbar />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="row justify-content-between mb-2">
              <div className="col-md-6"><h4 className='text-info'>Logbook</h4></div>
              <div className="col-md-6">
                <Button variant="secondary" className="float-end" onClick={handleShow}>
                  New Entry
                </Button>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Note</th>
                </tr>
              </thead>
              <tbody>
              {entries.map((entry, index) => {
                // Convert the ISO date string to a Date object
                const dateObject = new Date(entry.date);

                // Format the date as 'YYYY-MM-DD'
                const formattedDate = dateObject.toLocaleDateString('en-GB'); // 'en-GB' gives 'DD/MM/YYYY' format; change to 'en-US' for 'MM/DD/YYYY'

                // Format the time as 'HH:MM:SS'
                const formattedTime = dateObject.toLocaleTimeString('en-GB', { hour12: false }); // 24-hour format; remove hour12 for 12-hour format

                return (
                  <tr key={index}>
                    <td>{formattedDate}</td>
                    <td>{formattedTime}</td>
                    <td>{entry.message}</td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
          </div>
        </div>

        {/* Modal for New Entry */}
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Entry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formDate">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formTime">
                <Form.Label>Time</Form.Label>
                <Form.Control
                  type="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formNote">
                <Form.Label>Note</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={3}
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className="mb-3"/>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Logbook;

import React, { useState } from 'react';
import { Navbar, Nav, Button, Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const MyNavbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <>
<div className="container">

  

      {/* Navbar */}
      <Navbar bg="light" expand="lg" className="px-3">
        <Navbar.Brand href="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/Logo-X4AvrrgLl-transformed.png`}
            width="200"
            height="80"
            
            className="d-inline-block align-top object-fit-contain"
            alt="Logo"
          />
        </Navbar.Brand>
        {/* <Button variant="outline-secondary" className="ms-auto" onClick={toggleSidebar}> */}
        <Button className="ms-auto custom_menu_icon" onClick={toggleSidebar} style={{'border': 0}}>
        <img
            src={`${process.env.PUBLIC_URL}/images/menu_icon.jpg`}            
            className="d-inline-block"
            alt="icon"
          />
          {/* <span className="navbar-toggler-icon"></span> */}
          {/* <BiList size={24} /> */}
          {/* <i className="bi bi-list" style={{ fontSize: '24px' }}></i> */}
        </Button>
      </Navbar>

      {/* Sidebar controlled by Offcanvas */}
      <Offcanvas show={showSidebar} onHide={toggleSidebar} className="centered-offcanvas">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Apply XR</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
          <div className="cus_navbar">
      {/* <Link to="/" className="backtohome_btn"> <i className="bi bi-arrow-left"></i> Back To Home</Link> */}
      <ul>
        <li>
          <Link to="/mode" className="nav-item">
            <img src={`${process.env.PUBLIC_URL}/images/menu/mode.png`} alt="icon" className="icon" />
            <h4>Mode</h4>
          </Link>
        </li>
        <li>
          <Link to="/logbook" className="nav-item">
            <img src={`${process.env.PUBLIC_URL}/images/menu/logbook.png`} alt="icon" className="icon" />
            <h4>Logbook</h4>
          </Link>
        </li>
        <li>
          <Link to="/notifications" className="nav-item">
            <img src={`${process.env.PUBLIC_URL}/images/menu/notifications.png`} alt="icon" className="icon" />
            <h4>Notifications</h4>
          </Link>
        </li>
        <li>
          <Link to="/system-info" className="nav-item">
            <img src={`${process.env.PUBLIC_URL}/images/menu/system_info.png`} alt="icon" className="icon" />
            <h4>System info</h4>
          </Link>
        </li>
      </ul>
    </div>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
      </div>
    </>
  );
};

export default MyNavbar;

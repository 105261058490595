import React from "react";
import MyNavbar from "./MyNavbar";
import { Tab, Tabs, Carousel  } from 'react-bootstrap';
// import { Carousel } from 'react-bootstrap-carousel';

function SystemInfo() {



  const systemDocumentsImages = [
    `${process.env.PUBLIC_URL}/images/documents/1.png`,
    `${process.env.PUBLIC_URL}/images/documents/2.png`,
    `${process.env.PUBLIC_URL}/images/documents/3.png`,
    `${process.env.PUBLIC_URL}/images/documents/4.png`,
    `${process.env.PUBLIC_URL}/images/documents/5.png`,
    `${process.env.PUBLIC_URL}/images/documents/6.png`,
  ];

  return (
    <>
    <MyNavbar />
    <div className="container">
      <div className="system_info">

      <Tabs defaultActiveKey="systemInformation" id="documents-tabs" className="mb-3">
        <Tab eventKey="systemInformation" title="System Information">
          <div className="list-group">
              <img
            src={`${process.env.PUBLIC_URL}/images/pdf-im-1-min.jpg`}
            width="100%"
            height="500px"
            className="d-inline-block object-fit-contain"
            alt="Logo"
          />
          
          </div>
        </Tab>
        <Tab eventKey="systemDocuments" title="System Documents">
        <Carousel
                fade
                interval={3000} 
                autoplay={true}
                pauseOnHover={true}
                indicators={false}
                className="carousel-fade full-screen-slider"
              >
                {systemDocumentsImages.map((imageSrc, index) => (
                  <Carousel.Item key={index}>
                    <img
                      src={imageSrc}
                      alt={`Slide ${index + 1}`}
                      className="d-block im_item"
                      style={{ height: "calc(100vh - 130px)" }} // Fullscreen height minus navbar
                    />
                   <div className="carousel-caption">
                      <div className="slide-number-overlay">
                        {`Slide ${index + 1} of ${systemDocumentsImages.length}`}
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>          
          {/* <div className="list-group">
          <img
            src={`${process.env.PUBLIC_URL}/images/Minox-brosjyre-September-2019-04.png`}
            width="100%"
            height="500px"
            className="d-inline-block object-fit-contain"
            alt="Logo"
          />
          </div> */}
        </Tab>
      </Tabs>

      </div>
    </div>
    </>
  );
}

export default SystemInfo;

import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-moment';
import { getFormattedDateTime } from '../utils/dateUtils';
const TemperatureChart = ({ token, sensorIndex }) => {
    const [chartData, setChartData] = useState(null);

    // console.log(sensorIndex);
    // Function to fetch and update chart data
    const fetchChartData = async (token, sensorIndex) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);
    
        const raw = JSON.stringify({
            GatewayId: "APPLY1",
            PredictorId: "APPLY_EWON_ONS",
            SensorIndex: sensorIndex,
            FromDate: getFormattedDateTime(true), // one hour ago 
            ToDate: getFormattedDateTime(false), // current time

        });
    
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };
    
        try {
            const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Gateways/apply/sensordata", requestOptions);
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error("Error fetching sensor data: ", error);
            throw error;
        }
    };

    const updateChartData = async () => {
        try {
            const data = await fetchChartData(token, sensorIndex);
            // console.log("Fetched data:", data); // Debugging line to inspect the data
            setChartData(data);
        } catch (error) {
            console.error("Error updating chart data: ", error);
        }
    };

    useEffect(() => {
        updateChartData();
        const intervalId = setInterval(updateChartData, 30000); // Update every 30 seconds

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [sensorIndex]);

    if (!chartData || !chartData.predictorDataList) {
        return <div>Loading...</div>;
    }

    // Extract the necessary data from the `sensor` object
    // const { highHighLimit, highLimit, lowLimit, lowLowLimit, date, value } = chartData.predictorDataList;


    // Determine the yAxisLabel based on sensorIndex
    // let yAxisLabel;
    // if ([0, 1, 6, 7].includes(sensorIndex)) {
    //     yAxisLabel = '%';
    // } else if ([2, 3, 4].includes(sensorIndex)) {
    //     yAxisLabel = 'BAR';
    // } else if (sensorIndex === 5) {
    //     yAxisLabel = 'Temperature (°C)';
    // } else if (sensorIndex === 11) {
    //     yAxisLabel = 'l/h';
    // } else {
    //     yAxisLabel = ''; // Default label if none of the conditions match
    // }


    // Prepare the data for the chart
    const data = {
        labels: chartData.predictorDataList.map(entry => entry.date), // X-axis: Event Date (you can modify this if you have multiple entries)
        datasets: [
            {
                label: 'Value',
                data: chartData.predictorDataList.map(entry => entry.value), // Y-axis: Value
                fill: false,
                backgroundColor: 'rgba(43,42,40,0.4)',
                borderColor: 'rgba(43,42,40,1)',
                // borderDash: [5, 5], // Dashed line
                tension: 0.1
            },
            {
                label: 'HighHigh',
                data: chartData.predictorDataList.map(entry => entry.highHighLimit),
                fill: false,
                backgroundColor: 'rgba(220,68,55,0.4)',
                borderColor: 'rgba(220,68,55,1)',
                // borderDash: [5, 5], // Dashed line
                tension: 0.1
            },
            {
                label: 'High',
                data: chartData.predictorDataList.map(entry => entry.highLimit),
                fill: false,
                backgroundColor: 'rgba(254,204,89,0.4)',
                borderColor: 'rgba(254,204,89,1)',
                // borderDash: [5, 5], // Dashed line
                tension: 0.1
            },
            {
                label: 'Low',
                data: chartData.predictorDataList.map(entry => entry.lowLimit),
                fill: false,
                backgroundColor: 'rgba(101,227,189,0.4)',
                borderColor: 'rgba(101,227,189,1)',
                // borderDash: [5, 5], // Dashed line
                tension: 0.1
            },
            {
                label: 'LowLow',
                data: chartData.predictorDataList.map(entry => entry.lowLowLimit),
                fill: false,
                backgroundColor: 'rgba(57,115,212,0.4)',
                borderColor: 'rgba(57,115,212,1)',
                // borderDash: [5, 5], // Dashed line
                tension: 0.1
            },
        ]
    };

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'YYYY-MM-DD HH:mm A',
                    displayFormats: {
                        day: 'YYYY-MM-DD',
                    },
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                ticks: {
                    autoSkip: false,
                    maxRotation: 45,
                    minRotation: 45,
                }
            },
            y: {
                title: {
                    display: true,
                    text: chartData.sensor.unit, // Use the dynamic yAxisLabel
                    font: {
                        size: 17 // Adjust the font size for the x-axis title if needed
                    }
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top'
            }
        }
    };

    return <Line data={data} options={options} />;
};

export default TemperatureChart;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import MyNavbar from "./MyNavbar";
import VectaryModel from "./VectaryModel";
import TemperatureChart from "./TemperatureChart";

function Home({ token}) {


    const [sensorIndex, setSensorIndex] = useState(0); // Default to first sensor

    const handleSensorChange = (e) => {
      setSensorIndex(e.target.value);
  };
  
  // Define sensor labels
  const sensorLabels = [
    "LT 201",
    "LT 301",
    "PT 102",
    "PT 103",
    "PT 104",
    "TIT 401",
    "LCV 201",
    "LCV 301",
    "FT 102"
  ];

  return (
    <>
     <MyNavbar />
        <div className="container">
        
        {/* <div className="row">
            <div className="col-md-6"> </div>
         </div> */}

            {/* Vecatry Modal */}
            <div className="3d_modal_wrap mt-4">
                <VectaryModel token={token} />
            </div>

        {/* chart */}
        <div className="chart_temp mb-4">

        <div className="dropdown-container">
        {/* <h3 className="text-info">Temperature Over Time</h3> */}
            <label htmlFor="sensor-select" className="dropdown-label">Select Sensor:</label>
            <select
                id="sensor-select"
                className="dropdown-select"
                onChange={handleSensorChange}
                value={sensorIndex}
            >
                {sensorLabels.map((label, index) => (
                    <option key={index === 8 ? 11 : index} value={index === 8 ? 11 : index}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
        {token && <TemperatureChart token={token} sensorIndex={sensorIndex} />}
    </div>

        </div>
    </>
  );
}

export default Home;

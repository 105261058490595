import React, { useState } from 'react';
import { Button, Alert, Form } from 'react-bootstrap';

const Commands = ({token}) => {
  const [isOn, setIsOn] = useState(false);
  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });


  const handleToggle = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Cookie", "ARRAffinity=ca5ffd0b6d99becd7bc669f4937089ccc7bd60fd1a2bafaed8abfcec6ffcbde1; ARRAffinitySameSite=ca5ffd0b6d99becd7bc669f4937089ccc7bd60fd1a2bafaed8abfcec6ffcbde1");

    const raw = JSON.stringify({
      "DeviceId": "APPLY1",
      "PU_01_AutoCmd": isOn,
      "PU_01_StoptCmd": !isOn
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    try {
      const response = await fetch('https://dev-ideation-api.azurewebsites.net/api/SensorCommands/apply/EwonOpenContainer', requestOptions);

      if (response.ok) {
        setAlert({ show: true, variant: 'success', message: `System ${!isOn ? 'started' : 'stopped'} successfully!` });
      } else {
        setAlert({ show: true, variant: 'danger', message: `Failed to ${isOn ? 'start' : 'stop'} the system.` });
      }
    } catch (error) {
      setAlert({ show: true, variant: 'danger', message: `Error occurred while ${isOn ? 'starting' : 'stopping'} the system.` });
    }
  };

  const handleSwitchChange = (event) => {
    setIsOn(event.target.checked);
    handleToggle();
  };




  return (
    <div className="commands-section p-2">
      <h4 className="text-info">Commands</h4>

      <div className='d-flex justify-content-center mt-4'>
        <Form>
          <Form.Check 
            type="switch"
            id="custom-switch"
            label={isOn ? "System On" : "System Off"}
            checked={isOn}
            onChange={handleSwitchChange}
          />
        </Form>
      </div>

      {alert.show && (
        <Alert variant={alert.variant} className="mt-3">
          {alert.message}
        </Alert>
      )}
    </div>
  );
};

export default Commands;

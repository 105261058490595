import React, { useState } from "react";
import MyNavbar from "./MyNavbar";
import VectaryModel from "./VectaryModel";
import TemperatureChart from "./TemperatureChart";
import Commands from "./Commands";
import { Modal, Button, ListGroup } from 'react-bootstrap';

function Mode({ token}) {
  const [show, setShow] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = (mode) => {
    setSelectedMode(mode);
    setShow(true);
  };

  const modes = [
    {
      title: 'System Info',
      icon: `${process.env.PUBLIC_URL}/images/opra.png`,
      details: [
        'Real-time data feed from the assets (IoT & Ewon).',
        'Real-time operation actions.',
        'Alerts & notifications.',
        'Other asset general info & documentation.',
        'Logbook.',
      ],
    },
    {
      title: 'Training',
      icon: `${process.env.PUBLIC_URL}/images/presnt.png`,
      details: [
        'System general introduction course.',
        'Perform a training scenario.',
        'Take a quiz.',
        'System offline operations.',
      ],
      comingSoon: true,
    },
  ];



  // -------------------------------------------------
  // console.log('coming from mode component');
  // console.log(data);
  // console.log(token);
  const [sensorIndex, setSensorIndex] = useState(0); // Default to first sensor

  const handleSensorChange = (e) => {
    setSensorIndex(e.target.value);
};

  // Define sensor labels
  const sensorLabels = [
    "LT 201",
    "LT 301",
    "PT 102",
    "PT 103",
    "PT 104",
    "TIT 401",
    "LCV 201",
    "LCV 301",
    "FT 102"
  ];
  return (
    <>
    <MyNavbar />
    <div className="container pb-5">
      
      {/* Hiding Select Mode */}
    <div className="select_mode mb-4 text-center d-none">
      <h3 className="mb-3 text-info">Select Mode</h3>
      <div className="row justify-content-center">
        {modes.map((mode, index) => (
          <div className="col-md-2" key={index}>
            <div className="item_wrap d-flex clickable-item" onClick={() => handleShow(mode)}>
              <div className="left_wrap">
                <img src={mode.icon} alt="icon" className="icon" />
                <h4>{mode.title}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedMode?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <img src={selectedMode?.icon} alt="icon" className="icon me-3" style={{ width: '50px', height: '50px' }} />
            <div>
              <ListGroup variant="flush">
                {selectedMode?.details.map((detail, idx) => (
                  <ListGroup.Item key={idx}>
                    <i className="bi bi-check"></i> {detail}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {selectedMode?.comingSoon && (
                <h5 className="coming_soon text-danger mt-3">Coming Soon</h5>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
      

            {/* Commands */}

            <Commands token={token}/>

        {/* Vecatry Modal */}
        <div className="3d_modal_wrap mt-4">
            <VectaryModel token={token}/>
        </div>

        {/* chart */}
        <div className="chart_temp">

        <div className="dropdown-container">
        {/* <h3 className="text-info">Temperature Over Time</h3> */}
            <label htmlFor="sensor-select" className="dropdown-label">Select Sensor:</label>
            <select
                id="sensor-select"
                className="dropdown-select"
                onChange={handleSensorChange}
                value={sensorIndex}
            >
                {sensorLabels.map((label, index) => (
                    <option key={index === 8 ? 11 : index} value={index === 8 ? 11 : index}>
                        {label}
                    </option>
                ))}
            </select>
        </div>
        {token && <TemperatureChart token={token} sensorIndex={sensorIndex} />}
    </div>




    </div>
    </>
  );
}

export default Mode;

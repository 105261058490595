
import React, { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { getFormattedDateTime } from '../utils/dateUtils';

const VectaryModel = ({ token }) => {
    const [validSensorDataArray, setValidSensorDataArray] = useState([]);
    const [notificationData, setNotificationData] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state


  useEffect(() => {
    if (!token) return; // Only run the code if the token is available

    const loadVectaryApi = async () => {


      // Set up headers for the fetch requests
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      // Array of sensor indexes
      const sensorIndexes = ["0", "1", "2", "3", "4", "5", "6", "7"];

      // Fetch data for each sensor index
      const fetchSensorDataPromises = sensorIndexes.map(async (sensorIndex) => {
        const raw = JSON.stringify({
          GatewayId: "APPLY1",
          PredictorId: "APPLY_EWON_ONS",
          SensorIndex: sensorIndex,
          FromDate: getFormattedDateTime(false, 4), // 4 days ago
          ToDate: getFormattedDateTime(true), // Current time
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        try {
          const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Gateways/apply/sensordata", requestOptions);
          const textData = await response.text();

          try {
            return JSON.parse(textData);
          } catch (jsonError) {
            console.error(`Error parsing JSON for sensor ${sensorIndex}: `, jsonError);
            console.error(`Response Text: ${textData}`);
            return null;
          }
        } catch (error) {
          console.error(`Error fetching data for sensor ${sensorIndex}: `, error);
          return null;
        }
      });

      // Fetch notification data
      const fetchNotificationData = async () => {
        const raw = JSON.stringify({
          GatewayId: "APPLY1",
          PredictorId: "APPLY_EWON_ONS",
          SensorIndex: "5",
          PageSize: 1,
          PageNumber: 1,
          FromDate: getFormattedDateTime(false, 15),
          ToDate: getFormattedDateTime(true),
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
        };

        try {
          const response = await fetch("https://dev-ideation-api.azurewebsites.net/api/Gateways/apply/AllNotificationsList", requestOptions);
          const textData = await response.text();

          try {
            return JSON.parse(textData);
          } catch (jsonError) {
            console.error(`Error parsing notification JSON: `, jsonError);
            console.error(`Notification Response Text: ${textData}`);
            return null;
          }
        } catch (error) {
          console.error("Error fetching notification data: ", error);
          return null;
        }
      };

      // Wait for all fetches to complete
      const fetchedSensorDataArray = await Promise.all(fetchSensorDataPromises);
      const fetchedNotificationData = await fetchNotificationData();

      // // Log the fetched data for debugging
      // console.log('Fetched Sensor Data Array:', fetchedSensorDataArray);
      // console.log('Fetched Notification Data:', fetchedNotificationData);


      // Update state with the fetched data
      setValidSensorDataArray(fetchedSensorDataArray.filter(data => data !== null));
      setNotificationData(fetchedNotificationData.notificationsList);
      setLoading(false); // Mark loading as complete

      // Initialize Vectary API
      const { VctrModelApi } = await import("https://app.vectary.com/studio-lite/scripts/api.js");
      const modelApi = new VctrModelApi("VECTARY_EMBED_ID");
      await modelApi.init();

      // Dispatch events for each sensor's last value
      fetchedSensorDataArray.forEach((sensorData, index) => {
        if (sensorData?.sensor?.lastValue) {
          modelApi.dispatchEvent(`temperature${index}`, `${parseFloat(sensorData.sensor.lastValue).toFixed(2)}`);
        }
        // Dispatch for `popThresholdAccel` and `popDuration` only if index is 3
        if (index === 3 && sensorData?.sensor) {
          
          // console.log('sensorData.sensor.popThresholdAccel');
          // console.log(sensorData.sensor.popThresholdAccel);
          modelApi.dispatchEvent(`popAccel`, `${sensorData.sensor.popThresholdAccel}`);
          modelApi.dispatchEvent(`popDuration`, `${parseFloat(sensorData.sensor.popDuration)}`);
          // if (sensorData.sensor.popThresholdAccel) {
          //   modelApi.dispatchEvent(`popAccel`, `${sensorData.sensor.popThresholdAccel}`);
          // }
          // if (sensorData.sensor.popDuration) {
          // }
        }

      });

      // Dispatch events for notifications
      if (fetchedNotificationData?.notificationsList) {
          modelApi.dispatchEvent(`Notif0`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif1`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif2`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif3`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif4`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif5`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif6`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
          modelApi.dispatchEvent(`Notif7`, fetchedNotificationData.notificationsList[0].notification.notificationTypeMessage);
      }

    };

    loadVectaryApi();
  }, [token]);


  // Log the state data to verify
  useEffect(() => {
    console.log('Valid Sensor Data Array from state:', validSensorDataArray);
  }, [validSensorDataArray]);

  useEffect(() => {
    console.log('Notification Data from state:', notificationData);
  }, [notificationData]);


  if (loading) {
   ; // Use the spinner component
  }

  return (
    <>
    {loading && <div className="spinner-container"><h4 className='text-loading'>Modal Data is loading</h4><BeatLoader color="#01a5bd" css={{ margin: "0 auto" }} /></div>}
    <iframe
      title='3D Model'
      id="VECTARY_EMBED_ID"
      src="https://app.vectary.com/p/2OI38g6Xgctnpv4inTYve3"
      width="100%"
      height="480"
    ></iframe>
    
    </>
  );
};

export default VectaryModel;

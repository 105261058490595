import React from 'react';
import MyNavbar from './MyNavbar';


const Notifications = ({ notificationsList }) => {



  return (
    <>
    <MyNavbar />
    <div className='container'>
    <div className="notification-table-container">
      <h4 className="text-info">Notification</h4>
      <div className="table-responsive">
      <table className="notification-table table table-bordered">
        <thead>
          <tr>
            <th></th>
            <th>Date</th>
            <th>Name</th>
            <th>Type</th>
            <th>Status</th>
            <th>Value</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {notificationsList.length > 0 ? (
            notificationsList.map((item, index) => (
              <tr key={index}>
                <td><i className="bi bi-thermometer-half"></i></td>
                <td>{item.notification.date}</td> 
                <td>{item.notification.predictorId}</td>
                <td>{item.notification.sensorType}</td>
                <td>{item.notification.notificationTypeMessage}</td>
                <td>{item.notification.highHighLimit}</td>
                <td>{item.locationHierarchy.name}</td>
              </tr>
            ))
          ) : (
            <tr>
               {/* <td colSpan="4" className="no-data">No notifications available</td>  */}
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </div>
    </div>
    </>
  );
};

export default Notifications;
